import { vhFix } from './vh-fix';
import { initBlockTextExpander } from "./block-text-expander";
import { initBlockAccordion } from './block-accordion';
import { InitBlockSliderSmall } from './block-slider-small';
import { InitBlockSliderBig } from './block-slider-big';
import { InitBlockSliderLogo } from './block-slider-logo';
import { initBlockSpacer } from './block-spacer';
import { InitEventSlider } from './event-slider';
import { initNavigation } from './navigation';
import { initBlogFilter } from './blog';
import { initLightbox } from './lightbox';
import { initHeader } from './header';
import { initCalendarTrigger, initCopyUrl, initEventFilter } from "./calendar";
import { restoreSettings, toggleContrast, zoomText } from "./access-menu";
import { InitParallaxEffect } from './parallax';
import { InitAnimations } from './animations';
import { resizeNewsletter } from './newsletter';

vhFix();
initBlockTextExpander();
initBlockAccordion();
InitBlockSliderSmall();
InitBlockSliderBig();
InitBlockSliderLogo();
InitEventSlider();
initNavigation();
initBlogFilter();
initLightbox();
initHeader();
initCopyUrl();
initCalendarTrigger();
initEventFilter();
zoomText();
toggleContrast();
restoreSettings();
InitParallaxEffect();
InitAnimations();
initBlockSpacer();


// on resize
window.addEventListener('resize', () => {
    initBlockSpacer();
});