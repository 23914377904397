export const initBlockSpacer = () => {
    const isMobileViewPort = window.matchMedia("(max-width: 750px)").matches;
    const spacers = document.querySelectorAll('.block-spacer');

    if(!spacers) return;

    spacers.forEach(spacer => {
        const height =  (isMobileViewPort) ? spacer.dataset.heightMobile : spacer.dataset.heightDesktop;

        if(!height) {
            spacer.style.height = '0px';
        };

        spacer.style.height = height + 'px';
    });

}