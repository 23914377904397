export const initHeader = () => {
    const languageSelect = document.getElementById('languageselect')
    if (!languageSelect) {
        return
    }

    languageSelect.addEventListener('change', () => {
        const language = languageSelect.value
        const baseUrl = languageSelect.dataset.baseurl.replace('https://', '').replace('http://', '')

        if (language.length == 0) return

        const googleHostname = baseUrl.replaceAll('-', '--').replaceAll('.', '-')

        const url = `https://${googleHostname}.translate.goog?_x_tr_sch=${location.protocol.replace(
            ':',
            ''
        )}&_x_tr_sl=en&_x_tr_tl=${language}&_x_tr_hl=en&_x_tr_pto=wapp`

        location.href = url
    })
}
