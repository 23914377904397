export const initNavigation = () => {

    const closeNavigation = () => {
        let element = document.querySelectorAll('nav.menu');
        element[0].classList.toggle('show');

        let header = document.querySelectorAll('header.page');
        header[0].classList.toggle('show');

        document.body.classList.toggle('noscroll');
    }

    const closeBtn = document.querySelectorAll('.page nav .close_btn');

    closeBtn[0].addEventListener('click', (event) => {
        closeNavigation();
    });

    const menuBtn = document.querySelectorAll('.menu_btn');

    menuBtn[0].addEventListener('click', (event) => {
        let element = document.querySelectorAll('nav.menu');
        element[0].classList.toggle('show');

        let header = document.querySelectorAll('header.page');
        header[0].classList.toggle('show');

        document.body.classList.toggle('noscroll');
    });

    const menuAccordions = document.querySelectorAll('nav.menu span.plus, nav.menu span.minus');

    menuAccordions.forEach(accordion => {

        accordion.addEventListener('click', (event) => {

            const openElements = document.querySelectorAll('nav.menu li.open');
            openElements.forEach(element => {
                element.classList.remove('open');
            });

            let element = event.target.closest('li');
            element.classList.toggle('open');
        });

    });

    const menuAnchors = document.querySelectorAll('nav.menu a');

    menuAnchors.forEach(anchor => {

        anchor.addEventListener('click', () => {
            closeNavigation();
        });

    });

}